import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { baseUrl, mapsApiKey } from "env";
import { headTitle } from "utils";

window.initMap = () => {};

export default connect(({ location, page, router, scripts, locale }) => {
	const { menuRoot = {}, lang } = location;
	const { name } = menuRoot;
	const title = headTitle(name && name[lang]);

	return {
		lang: location.lang,
		title,
		canonical: baseUrl + (page.path || (router.location || {}).pathname),
		scripts,
		locale
	};
})(
	class extends React.Component {
		render() {
			const { lang, title, canonical, scripts, locale } = this.props;
			return (
				<Helmet>
					{lang && <html lang={lang} />}

					{title && <title>{title}</title>}
					{title && <meta property="og:title" content={title} />}

					<meta
						name="description"
						content={locale.META_DESCRIPTION}
					/>
					<link rel="canonical" href={canonical} />
					{scripts.maps && (
						<script
							src={`https://maps.googleapis.com/maps/api/js?key=${mapsApiKey}&callback=initMap&libraries=&v=beta`}
							defer
						/>
					)}
				</Helmet>
			);
		}
	}
);
