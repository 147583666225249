
import cases from './casesReducer'
import coordinates from './coordinatesReducer'
import location from './locationReducer'
import locale from './localeReducer'
import filter from './filterReducer'
import news from './newsReducer'
import page from './pageReducer'
import timeline from './timelineReducer'

import scripts from './scriptsReducer'
import search from './searchReducer'
import twitter from './twitterReducer'
import ui from './uiReducer'
import team from './teamReducer'
import whatwedo from './whatwedoReducer'

export default{
 cases,
 coordinates,
 location,
 locale,
 filter,
 news,
 page,
 scripts,
 search,
 timeline,
 twitter,
 ui,
 team,
 whatwedo
}

