import React from "react";
import { connect } from "react-redux";
import BehindTheNumbers from "./BehindTheNumbers";

const Banner = connect(({ location, page }) => {
    const { menuRoot } = location;


    return {
        menuRoot,
        lang: location.lang
    };
})(
    class extends React.Component {
        render() {
            const { menuRoot = {}, lang } = this.props;
            const { template } = menuRoot;
            return lang?<BehindTheNumbers open={template==="front"} lang={lang} />:null
          
        }
    }
);

export default Banner;
