import React from "react";
import { Link } from "components";




const MenuTree = class extends React.Component {

	/*removeThis(e){
	e.target.remove()
}*/
	render() {
		const { lvl = 1, footer, tree } = this.props;

		return (
			<menu>
				{tree.map((item, nr) => (
					<React.Fragment key={nr.toString()}>
						{/*footer && tree.length >= 12 && lvl >= 3 && nr === 10 ? (
							<li
								className="truncater cp"
								title="meer links weergeven"
								onClick={this.removeThis}
							>
								⋯
							</li>
						) : null*/}

						<li
							className={`lvl-${lvl} ${
								footer && lvl === 1
									? `span-wrap wrap-${item.children?item.children.length:0}`
									: ""
							}`}
							style={
								footer && lvl === 1
									? {
											gridColumn: `auto/span ${
												item.children?item.children.length:0
											}`
									  }
									: null
							}
						>
							<Link to={item.href}>{item.title}</Link>
							{item.children ? (
								<MenuTree footer={footer} tree={item.children} lvl={lvl + 1} />
							) : null}
						</li>
					</React.Fragment>
				))}
			</menu>
		);
	}
};

export default MenuTree