const defaultState = {};

export default (state = defaultState, action) => {
	switch (action.type) {
		case "CLEAR_FILTERS":
		return defaultState
		case "NEWS_TYPE":
			return { ...state, newsType:action.newsType };
		case "NEWS_QUERY":
			return { ...state, q: action.q, filteredNews:null };
		case "FILTERED_NEWS_RECEIVED":
			return {
				...state,
				filteredNews:(action.filteredNews||{}).data
			}
		default:
			return state;
	}
};
