const defaultState = {};

export default (state = defaultState, action) => {
	switch (action.type) {
		case "PAGE_RECEIVED":
			return {...state,[action.source]:action.page};
		default:
			return state;
	}
};
