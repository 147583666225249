const defaultState = {};

export default (state = defaultState, action) => {
	switch (action.type) {
		case "TOGGLE_MOBILE_MENU":
			return {
				...state,
				mobileMenuOpen: !state.mobileMenuOpen
			};
		case "CLOSE_MOBILE_MENU":
			return {
				...state,
				mobileMenuOpen: false
			};
		case "@@router/LOCATION_CHANGE":
			const { pathname } = action.payload.location;
			const basePath = pathname
				.split("/")
				.slice(0, 3)
				.join("/");
			const ret = {
				...state
			};
			if (state.langSwitch) {
				if (
					!~Object.keys(state.langSwitch)
						.map(k => `/${k}${state.langSwitch[k]}`)
						.indexOf(action.payload.location.pathname)
				) {
					ret.langSwitch = null;
				}
			}
			if ((action.payload || {}).isFirstRendering) {
				ret.basePath = basePath;
			}

			if (
				basePath !== state.basePath ||
				(~basePath.indexOf("/newsroom") &&
					(pathname.split("/")[3] || "").match(/^\d+$/))
			) {
				ret.basePath = basePath;
				ret.scrollPosStamp = (Math.random() + 1)
					.toString(36)
					.substring(7);
			}
			return ret;

		case "WEBP_SUPPORT":
			return {
				...state,
				imgExtension: action.supported ? "webp" : "jpg"
			};
		case "LANG_SWITCH_OVERRIDE":
			return {
				...state,
				langSwitch: action.langSwitch
			};
		default:
			return state;
	}
};
