import React from "react";
import { isCrawler } from "env";
import "./BehindTheNumbers.css";

const ANIM_START_TIME = 800;
const ANIM_REFRESH_RATE = 40;

function randomString(len, charSet) {
    charSet = charSet || "0123456789";
    var randomString = "";
    for (var i = 0; i < len; i++) {
        var randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
}

const text = {
    en: "The story behind the numbers",
    nl: "Het verhaal achter de cijfers"
};

export default class extends React.Component {
        constructor(props) {
            super(props);
            this.ref = React.createRef();
            this.showAnimation = props.open && !isCrawler;
            
            this.sentence = text[props.lang];
            this.fontSize = `calc((100vw - var(--gap) * 2)/${0.615 *
                this.sentence.length}`;
            if (this.showAnimation) {
                this.text = randomString(this.sentence.length);
            } else {
                this.text = this.sentence;
            }
        }

        componentWillUnmount() {
            clearTimeout(this.animation);
        }
        componentDidUpdate({ lang }) {
            if (lang && lang !== this.props.lang) {
                //language switched
                clearTimeout(this.animation);
                this.ref.current.textContent = text[this.props.lang];
            }
        }
        componentDidMount() {
            if (this.showAnimation) {
                let indices = Array(this.sentence.length)
                    .fill()
                    .map((_, i) => i);
                let text = this.text;
                const sentence = this.sentence;

                const animate = () => {
                    //  const { text, sentence } = this.state;
                    // let newIndices = [...indices];

                    const replaceIndex = indices.splice(
                        Math.floor(Math.random() * indices.length),
                        1
                    )[0];
                    text =
                        text.substr(0, replaceIndex) +
                        sentence.substr(replaceIndex, 1) +
                        text.substr(replaceIndex + 1);
                    this.ref.current.textContent = text;

                    /*  this.setState(
                {
                    indices: newIndices,
                    text: newText
                },
                () => {*/
                    if (indices.length) {
                        this.animation = setTimeout(animate, ANIM_REFRESH_RATE);
                    }
                };
                //);
                // };

                this.animation = setTimeout(animate, ANIM_START_TIME);
            }
        }

        render() {
            const {open}=this.props
            return (
                <div className={`behindthenumbers-cont${open?' open':''}`} style={{
                        fontSize: this.fontSize
                    }}>
                <div
                    className={`behindthenumbers`}
                   
                >
                    <p ref={this.ref}>{this.text}</p>
                </div>
                </div>
            );
        }
    }