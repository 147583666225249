const defaultState = [];

export default (state = defaultState, action) => {
	switch (action.type) {
		case "TWITTER_RECEIVED":
			return action.feed||[];

		default:
			return state;
	}
};
