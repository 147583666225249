import { addCacheTimestamp } from "utils";
const defaultState = false;

export default (state = defaultState, action) => {
	switch (action.type) {
		case "TEAM_RECEIVED":
			return addCacheTimestamp({ team: action.team });
		default:
			return state;
	}
};
