module.exports=[
    {
        path: "/",
        name: "Home",
        template: "front",
        bg: "nemo"
    },
    {
        path: "/newsroom",
        name: {
            en: "News",
            nl: "Nieuws"
        },
        template: "news",
        bg: "zuidas"
    },
    {
        ref: "who-we-are",
        source: "/page/about",
        bg: "rode-brug",
        path: {
            en: "/about-spj",
            nl: "/over-ons"
        },
        name: {
            en: "Who we are",
            nl: "Wie we zijn"
        },
          short: {
            en: "Who",
            nl: "Wie"
        },
        template: ["text-sections", "team", "timeline"],
        children: [
            {
                path: {
                    en: "/about",
                    nl: "/over"
                },
                name: {
                    en: "About SPJ",
                    nl: "Over SPJ"
                }
            },
            {
                path: "/team",
                name: "Team",
                type: "team"
            },
            {
                path: "/track-record",
                name: "Track record",
                type: "timeline"
            }
        ]
    },
    {
        ref: "what-we-do",
        path: {
            en: "/what-we-do",
            nl: "/wat-we-doen"
        },
        bg: "europarking",
        short:{
            en:"What",
            nl:"Wat"
        },
        name: {
            en: "What we do",
            nl: "Wat we doen"
        },
        storeKey: "whatwedo",
        template: "text-sections"
    },
    {
        source: "/page/network",
        bg: "rembrandttoren",
        path: {en: "/international-network", nl: "/internationaal-netwerk"},
        name: {en: "International network", nl: "Internationaal netwerk"},
        short:{en:"Int. network", nl:"Int. netwerk"},
        template: "default",
    },
    {
        path: {en: "/clients-and-cases", nl: "/opdrachtgevers"},
        bg: "conservatorium",
        name: {en: "Clients", nl: "Opdrachtgevers"},
        template: "cases",
        children: [
            {
                name: {
                    en: "Construction & Real estate",
                    nl: "Bouw en vastgoed"
                },
                path: {en: "/construction-real-estate", nl: "/bouw-vastgoed"}
            },
            {
                name: {
                    en: "Financial services",
                    nl: "Financiële dienstverlening"
                },
                path: {
                    en: "/financial-services",
                    nl: "/financiele-dienstverlening"
                }
            },
            {name: "Life sciences", path: "/life-sciences"},
            {name: "M&A", path: "/m-and-a"},
            {
                name: {en: "Other", nl: "Overige"},
                path: {en: "/other", nl: "/overige"}
            }
        ]
    },
    {
        path: "/contact",
        template: ["contact", "team-short"],
        source: "/page/contact",
        name: {
            en: "Contact",
            nl: "Contact"
        }
    },
    {
        menu: "footer",
        source: "/page/4",
        path: "/disclaimer",
        name: "Disclaimer",
        template: "default",
        bg: "zuidas"
    },
    {
        menu: "footer",
        source: "/page/5",
        path: "/site-credits",
        name: "Website Credits",
        template: "default",
        bg: "zuidas"
    }
]