import React from "react";
import { connect } from "react-redux";
import { ContentSection, RawHtml, Image, Link } from "components";
import { Cross } from "icons";
import "./Cases.css";
import enhanceWithClickOutside from "react-click-outside";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import {linksToBlank} from "utils"

const CasesPage = connect(({ cases, location, locale }) => {
	const { list } = cases;
	const { menuRoot, menuItem } = location;
	let filteredList;
	if (list) {
		filteredList = list;

		/*if (menuItem) {
			const match = location.path.match(
				new RegExp(`${menuItem.path}(.*)$`)
			);

			if (match && match[1]) {
				const sector = match[1].slice(1);
				const index = menuItem.children.findIndex(
					({ path }) => path === `/${sector}`
				);
				if (~index) {
					filteredList = list.filter(c => c.type === index);
				}
			}
		}*/
		if (menuItem) {
			filteredList = list.filter(c => c.type === menuItem.index);
		}
		/*filteredList=filteredList.sort((a, b) => {
									var nameA = a.name.toLowerCase(),
										nameB = b.name.toLowerCase();
									if (nameA < nameB) {
										return -1;
									}
									if (nameA > nameB) {
										return 1;
									}
									return 0;
							  })*/
	}
	return {
		list: filteredList,
		lang: location.lang,
		menuRoot,
		menuItem,
		locale
	};
})(
	class extends React.Component {
		state = {};

		componentDidMount() {
			this.props.dispatch({
				type: "CASES_FETCH"
			});
		}
shouldComponentUpdate(nextProps,{open}) {
        return open!==this.state.open||JSON.stringify(nextProps) !== JSON.stringify(this.props);
    }
		onOpen(e, i) {
			if (!e.ctrlKey) {
				e.preventDefault();
				this.setState({ open: i });
			}
		}
		onClose() {
			this.setState({ open: null });
		}
		locale(prop) {
			if (!prop) {
				return "";
			}
			const { lang } = this.props;
			return typeof prop === "string" ? prop : prop[lang];
		}
		render() {
			const { menuRoot, list = [], lang, locale } = this.props;
			const { path: rootPath, children = [] } = menuRoot || {};
			const { open } = this.state;
			const rootPathLocale = this.locale(rootPath);
			return (
				<ContentSection>
					<div className="content-width content-grid content-grid-square">
						{children && (
							<div className="block block-content case-links">
								<h2 className="case-links-title block-title">
									Sectors
								</h2>
								<Link
									nav={true}
									exact={true}
									className="case-link"
									to={`${rootPathLocale}`}
								>
									{locale.ALL}
								</Link>
								{children.map(({ path, name }, i) => (
									<Link
										nav={true}
										key={i.toString()}
										className="case-link"
										to={`${rootPathLocale}${this.locale(
											path
										)}`}
									>
										{this.locale(name)}
									</Link>
								))}
							</div>
						)}

						{(list.length
							? list
							: Array(47).fill({})
						).map(({ name, url, logo, description, id }, i) => {

							const isOpen = open === i;

							const d = this.locale(description);
							let n;

							if (name) {
								n = this.locale(name);
								if (!n && lang === "en" && name.nl) {
									n = name.nl;
								}
							}
							return (
								<div
									key={(id||'i'+i).toString()}
									className={`block case-block${
										typeof open === "number"
											? isOpen
												? " is-open"
												: " is-not-open"
											: ""
									}`}
								>
									<a
										href={url}
										target="_blank"
										rel="noopener noreferrer"
										className={`case-logo${
											logo ? "" : " block-ctr-title"
										}`}
										onClick={
											name ? e => this.onOpen(e, i) : null
										}
									>
										{d && (
											<span className="case-label">
												Case
											</span>
										)}
										{logo ? (
											<Image
												alt={n}
												className="case-img"
												src={logo}
											/>
										) : (
											<h2 className="red">{n}</h2>
										)}
									</a>
									{isOpen && (
										<OpenCase
											onClose={() => this.onClose()}
											description={d}
											url={url}
											name={n}
										/>
									)}
								</div>
							);
						})}
					</div>
				</ContentSection>
			);
		}
	}
);
export default CasesPage;

const OpenCase = enhanceWithClickOutside(
	class extends React.Component {
		handleClickOutside() {
			this.props.onClose();
		}
		render() {
			const { name, url, description, onClose } = this.props;
			return (
				<React.Fragment>
					<div className="content-grid content-grid-square case-inner-grid">
						<div className="case-close" onClick={onClose}>
							<Cross />
						</div>
						<ScrollIntoViewIfNeeded
							options={{
								scrollMode: "if-needed",
								block: "start",
								behavior: "smooth"
							}}
							style={{
								paddingTop: 90,
								position: "absolute",
								height: "100%",
								bottom: 0,
								boxSizing: "initial"
							}}
							children={<div />}
						/>

						<div />
						<div className="block-content case-summary">
							<h2 className="block-title">{name}</h2>
							<div className="block-lead">
								<a
									className="red"
									href={url}
									target="_blank"
									rel="noopener noreferrer"
								>
									{url}
								</a>
							</div>
						</div>
						{description && (
							<RawHtml
								html={linksToBlank(description)}
							
									style={{
										gridRowEnd: `span ${Math.ceil(
											description.length / 600
										)}`
									}}
									className="span-2 block-content case-article article"
							/>
						)}
					</div>
				</React.Fragment>
			);
		}
	}
);
