const defaultState = {};

export default (state = defaultState, action) => {
	switch (action.type) {
		case "ADD_SCRIPT":
			return {
				...state,
				[action.script]:1
			};
		default:
			return state;
	}
};
