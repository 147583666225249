import React from "react";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {
    ContentSection,
    RawHtml,
    Image,
    Link,
    TwitterFeed,
    OnlyOnDesktop,
    NotOnDesktop,
    Throbber
} from "components";
import {Favicon} from "icons";
import {slugify, headTitle, articlePath, linksToBlank} from "utils";
import {
    perPageDefault,
    promotedAmount,
    attachmentsCdnUrl,
    articleGroups
} from "env";
import {Cross} from "icons";
import "./News.css";


const NewsPage = connect(({location, news: allNews = {}, locale, filter}) => {
    const {lang, path} = location;
    const parts = path.split("/");
    const basePath = parts.slice(0, 2).join("/");

    const arg = parts[2] || "";
    let pass;
    if (arg.match(/^\d+$/)) {
        //single blog post

        const articleId = ~~arg;
        pass = {
            mode: "single",
            articleId,
            article: allNews[articleId]
        };
    } else {
        //list
        const {newsType} = filter
        const filterOnType = typeof newsType === "number";
        const match = arg.match(/^p([0-9]+)$/);
        const index = match ? Math.max(1, ~~match[1]) : 1;
        const newsLang = allNews[lang] || {};
        const newsLangFilteredOnType = allNews[filterOnType ? newsType + '-' + lang : lang] || {};
        const {last_page, per_page = perPageDefault, total} = newsLangFilteredOnType;

        let toShow = ~~per_page;
        if (index * per_page > total) {
            toShow = Math.max(0, per_page - (index * per_page - total));
        }

        const news =
            (newsLangFilteredOnType[index] && newsLangFilteredOnType[index].map(id => allNews[id])) ||
            Array(toShow).fill({});
        pass = {
            mode: "list",
            filterMode: !!(filter.q||"").trim(),
            filteredNews: filter.filteredNews && (typeof newsType === "number" ? filter.filteredNews.filter(n => n.type === newsType) : filter.filteredNews),
            newsType,
            newsLatest: (newsLang.promoted || {}).data,
            news,
            index,
            last_page: last_page - 1
        };
    }


    return {
        lang,
        basePath,

        locale,
        ...pass
    };
})(
    class extends React.Component {
        state = {};

        shouldComponentUpdate(nextProps) {
            return JSON.stringify(nextProps) !== JSON.stringify(this.props);
        }

        componentDidMount() {
            this.componentDidUpdate();
        }

        componentDidUpdate({article} = {}) {
            const {lang, index, articleId, mode, newsType, filterMode} = this.props;
            if (mode === "list") {
                if (!filterMode) {
                    if (lang !== this.state.lang || index !== this.state.index || newsType !== this.state.newsType) {

                        this.setState({lang, index, newsType}, () => {
                            const {newsLatest} = this.props;
                            if (newsLatest && newsLatest.length) {
                                this.props.dispatch({
                                    type: "NEWS_FETCH",
                                    lang,
                                    page: index,
                                    per_page: perPageDefault,
                                    newsType
                                });
                            } else {
                                this.props.dispatch({
                                    type: "NEWS_FETCH",
                                    lang,
                                    page: index,
                                    promoted: promotedAmount,
                                    per_page: perPageDefault,
                                    newsType
                                });
                            }
                        });
                    }
                }
            } else {
                if (articleId !== this.state.articleId) {
                    this.setState({lang, articleId}, () => {
                        this.props.dispatch({
                            type: "NEWS_FETCH",
                            lang,
                            articleId
                        });
                    });
                }
                if (this.props.article) {
                    if (!article || article.id !== this.props.article.id) {
                        const langSwitch = {};
                        const {title = {}, id} = this.props.article;
                        for (const key in title) {
                            langSwitch[key] = articlePath(id, title[key]);
                        }
                        this.props.dispatch({
                            type: "LANG_SWITCH_OVERRIDE",
                            langSwitch
                        });
                    }
                }
            }
        }

        locale(prop) {
            if (!prop) {
                return "";
            }
            const {lang} = this.props;
            return typeof prop === "string" ? prop : prop[lang];
        }

        onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }

        pager() {
            const {basePath, index, last_page} = this.props;

            if (last_page && last_page > 1) {
                const pages =
                    last_page < 6
                        ? Array(last_page + 1)
                            .fill(null)
                            .map((_, i) => i)
                        : [
                            0,
                            ...[
                                index - 3,
                                index - 2,
                                index - 1,
                                index,
                                index + 1
                            ],
                            last_page
                        ]
                            .filter(this.onlyUnique)
                            .filter(n => n >= 0 && n <= last_page);
                let pagesWithDots = [];
                let prev;
                for (const p of pages) {
                    if (p - prev > 1) {
                        pagesWithDots.push("...");
                    }
                    pagesWithDots.push(p);
                    prev = p;
                }

                return (
                    <React.Fragment>
                        {index > 1 ? (
                            <Link
                                to={`${basePath}${
                                    index - 2 ? "/p" + (index - 1) : ""
                                    }`}
                            >
                                <Favicon className="pager-icon left"/>
                            </Link>
                        ) : (
                            <span/>
                        )}
                        {pagesWithDots.map((i, idx) =>
                            typeof i === "string" ? (
                                <span key={`dots-${idx}`}>{i}</span>
                            ) : (
                                <Link
                                    nav={true}
                                    exact={true}
                                    key={i.toString()}
                                    to={`${basePath}${i ? "/p" + (i + 1) : ""}`}
                                >
                                    {i + 1}
                                </Link>
                            )
                        )}
                        {index < last_page ? (
                            <Link to={`${basePath}${"/p" + (index + 1)}`}>
                                <Favicon className="pager-icon"/>
                            </Link>
                        ) : (
                            <span/>
                        )}
                    </React.Fragment>
                );
            }
            return null;
        }

        render() {
            switch (this.props.mode) {
                case "list":
                    return this.listView();
                default:
                    return this.singleView();
            }
        }

        listView() {
            const {
                news,
                filteredNews,
                filterMode,
                locale
            } = this.props;

            return (
                <ContentSection>
                    <div className="content-grid-rows content-width">
                        <NewsTop/>

                        <div
                            className="content-width block"
                            style={{flexDirection: "column"}}
                        >
                            <Filters/>

                            {filterMode && !filteredNews ? (
                                <div
                                    className="container"
                                >
                                    <Throbber/>
                                </div>
                            ) : null}

                            {((filterMode ? filteredNews : news) || []).map(
                                (
                                    {
                                        type,
                                        title,
                                        image,
                                        description,
                                        id = ""
                                    },
                                    i
                                ) => {
                                    const Component = id ? Link : "div";

                                    const localeTitle = this.locale(
                                        title || " "
                                    );
                                    const props = {
                                        key: `${i}-${id}`,
                                        className:
                                            "news-row news-row-small block-link border-block",
                                        to: id
                                            ? articlePath(id, localeTitle)
                                            : null
                                    };
                                    return (
                                        <Component {...props}>

                                            <div className="news-row-item">

                                                {image ? (
                                                    <Image
                                                        src={image}
                                                        className="thumb"
                                                    />
                                                ) : (
                                                    <div className="thumb default">
                                                        <h2>
                                                            {this.locale(
                                                                articleGroups[type]
                                                            )}
                                                        </h2>
                                                    </div>

                                                )}

                                                <div className="news-row-small-title">
                                                    <h2 className="block-title">
                                                        {localeTitle}
                                                    </h2>

                                                    <span className="block-label">
													{this.locale(
                                                        articleGroups[type]
                                                    )}
												</span>


                                                    <OnlyOnDesktop>
                                                        <span className="news-row-small-content">
												<RawHtml
                                                    truncate={4}
                                                    className="block-lead"
                                                    html={this.locale(
                                                        description
                                                    ) || ""}
                                                />

												<span className="read-more">
													{locale.READ_MORE + "..."}
												</span>
											</span>
                                                    </OnlyOnDesktop>


                                                </div>

                                            </div>


                                            <NotOnDesktop>
                                                <span className="news-row-small-content">
												<RawHtml
                                                    truncate={4}
                                                    className="block-lead"
                                                    html={this.locale(
                                                        description
                                                    ) || ""}
                                                />

												<span className="read-more">
													{locale.READ_MORE + "..."}
												</span>
											</span>
                                            </NotOnDesktop>


                                        </Component>
                                    );
                                }
                            )}
                            {!filterMode ? (
                                <div className="pager-cont">
                                    <div className="pager">{this.pager()}</div>
                                </div>
                            ) : (filteredNews && !filteredNews.length && <div>
                                <div className="container">
                                    <h2 className="title">{locale.NO_RESULTS}</h2>

                                    <div className="button"
                                         onClick={() => this.props.dispatch({type: "CLEAR_FILTERS"})}>{locale.CLEAR_FILTERS}</div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </ContentSection>
            );
        }

        prevNext(o, children) {
            const {basePath, lang} = this.props;
            const obj = o && o[lang];
            const props = obj
                ? {
                    to: `${basePath}/${obj.id}/${slugify(obj.title)}`,
                    className: "prevnext"
                }
                : {
                    className: "prevnext inactive"
                };
            const Component = obj ? Link : "span";
            return <Component {...props}>{children}</Component>;
        }

        singleView() {
            const {article = {}, locale, basePath} = this.props;
            const {
                image,
                title,
                description,
                prev_article,
                next_article,
                files,
                type
            } = article;
            const titleLocale = this.locale(title);

            return (
                <ContentSection className="article-single">
                    <Helmet>
                        <title>{headTitle(titleLocale)}</title>
                    </Helmet>
                    <div className="content-width content-grid content-grid-square">
                        <div className={`article-img${image?'':' empty'}`}>
                            {image && (
                                <Image
                                    src={image}
                                    className="article-thumb usn"
                                />
                            )}
                        </div>
                        <div className="block block-content block-article article">
                            <div className="title">
                                <h2>{titleLocale}</h2>
                                <span className="block-label">
													{this.locale(
                                                        articleGroups[type]
                                                    )}
												</span></div>


                            <RawHtml html={linksToBlank(this.locale(description))}/>

                            {files && files.length ? (
                                <div>
                                    <br/>
                                    <span className="stronger">
										{locale.ATTACHMENTS + ":"}
									</span>
                                    <br/>
                                    {files.map(url => (
                                        <div key={url}>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={url.replace(
                                                    /^files\//,
                                                    attachmentsCdnUrl
                                                )}
                                            >
                                                {url.split("/").pop()}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                        </div>
                        <div className="block block-content usn article-browser">
                            <Link to={basePath} className="browser-back">
                                {locale.BACK_BLOG}
                            </Link>
                            {this.prevNext(
                                prev_article,
                                <React.Fragment>
                                    <Favicon className="pager-icon left"/>{" "}
                                    {locale.PREV_BLOG}
                                </React.Fragment>
                            )}
                            {this.prevNext(
                                next_article,
                                <React.Fragment>
                                    <Favicon className="pager-icon"/>{" "}
                                    {locale.NEXT_BLOG}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </ContentSection>
            );
        }
    }
);
export default NewsPage;

const Filters = connect(({filter, location, locale}) => ({
    filter,
    lang: location.lang,
    locale
}))(
    class extends React.Component {
        componentDidUpdate({lang: prev}) {

            const {filter, lang} = this.props;
            const {q} = filter
            if (q && lang !== prev) {
                this.props.dispatch({
                    type: "NEWS_QUERY",
                    q,
                    lang
                });
            }
        }

        search(q) {
            
            //clearTimeout(this.tm);
            const {filter, lang} = this.props;
            const {q: prev} = filter;
            if (q !== prev) {
                this.props.dispatch({
                    type: "NEWS_QUERY",
                    q,
                    lang
                });
            }

            if (q.trim() !== (prev||"").trim()) {
                this.props.dispatch({
                    type: "NEWS_QUERY_FETCH",
                    q,
                    lang
                });
            }
        }

        locale(prop) {
            if (!prop) {
                return "";
            }
            const {lang} = this.props;
            return typeof prop === "string" ? prop : prop[lang];
        }

        setType(newsType) {
            const {lang} = this.props
            this.props.dispatch({
                type: "NEWS_TYPE",
                newsType,
                lang
            });
        }

        render() {
            const {locale, filter} = this.props;
            const {newsType} = filter;

            return (
                <div className="filters">

                    <div className="filter-q-cont">
                        <input
                            className="filter-q"
                            type="text"
                            value={filter.q || ''}
                            placeholder={locale.SEARCH}
                            onChange={() => {
                            }}
                            onInput={e => this.search(e.target.value)}
                        />
                        {filter.q && <div className="clear-input" onClick={() => this.search('')}><Cross/></div>}
                    </div>

                    <div className="tab-links">


                        <Link to="/newsroom" className={`tab-link${!newsType && newsType !== 0 ? ' active' : ''}`}
                              onClick={() => this.setType(null)}>{locale.ALL}</Link>

                        {Object.keys(articleGroups).map((id, i) => (
                            <Link to="/newsroom" key={i.toString()}
                                  className={`tab-link${newsType === ~~id ? ' active' : ''}`}
                                  onClick={() => this.setType(~~id)}>
                                {this.locale(articleGroups[id])}
                            </Link>
                        ))}
                    </div>
                </div>
            );
        }
    }
);


const NewsTop = connect(({news, location, locale}) => {

    const {lang} = location
    const newsLang = news[lang] || {};

    return {
        locale,
        lang,
        newsLatest: (newsLang.promoted || {}).data
    }


})(class extends React.Component {
    locale(prop) {
        if (!prop) {
            return "";
        }
        const {lang} = this.props;
        return typeof prop === "string" ? prop : prop[lang];
    }

    render() {
        const {newsLatest, locale} = this.props


        return (<div className="content-grid content-grid-square">
            {(newsLatest || Array(promotedAmount).fill({})).map(
                (
                    {
                        type,
                        title,
                        image,
                        description,
                        id = ""
                    },
                    i
                ) => {
                    const props = {
                        key: `${i}-${id}`,
                        className:
                            "news-row block block-link news-latest news-latest-" +
                            (i + 1)
                    };
                    if (!id) {
                        return <div {...props} />;
                    }
                    const localeTitle = this.locale(title);
                    return (
                        <Link
                            {...props}
                            to={articlePath(id, localeTitle)}
                        >
                            {image && (
                                <Image
                                    src={image}
                                    className="thumb"
                                />
                            )}

                            <span className="block-content">
												<h2 className="block-title">
													{localeTitle}
												</h2>

												<span className="block-label">
													{this.locale(
                                                        articleGroups[type]
                                                    )}
												</span>

												<RawHtml
                                                    truncate={4}
                                                    className="block-lead"
                                                    html={
                                                        this.locale(
                                                            description
                                                        ) || ""
                                                    }
                                                />

												<span className="read-more">
													{locale.READ_MORE + "..."}
												</span>
											</span>
                        </Link>
                    );
                }
            )}
            <TwitterFeed className="block twitter-block"/>
        </div>)


    }
})
