import React from "react";
import {connect} from "react-redux"
import { Link, NavLink } from "react-router-dom";

export default connect(({location},{lang})=>({
	lang:lang||location.lang
}))(class extends React.Component {
	render(){
		const {nav,dispatch,to='/',lang,...rest}=this.props
		const Component=nav?NavLink:Link
		const path=to==='/'?'':to;
		const pathWithLang=lang+path
		return <Component to={`/${pathWithLang}`}{...rest}/>
	}
	})
