const defaultState = {};

export default (state = defaultState, action) => {
	switch (action.type) {
		case "LOCATION_SET":
			return action.payload;

		default:
			return state;
	}
};
