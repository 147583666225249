import React from "react";
import { connect } from "react-redux";
import { ContentSection, RawHtml, OfficeMap } from "components";
import {emailAddress} from "env"

const ContactPage = connect(({ location, page,locale }) => ({
	lang: location.lang,
	pageData: page[(location.menuRoot || {}).source || -1],
	locale
}))(
	class extends React.Component {
		locale(prop, lang) {
			if (!prop) {
				return null;
			}
			return typeof prop === "string"
				? prop
				: prop[lang || this.props.lang];
		}
		render() {
			const { pageData = {}, lang, locale } = this.props;
			const { title, content, extras = {} } = pageData;

			const address = this.locale(content);
			let addressOneLine;
			if (address) {
				var tmp = document.createElement("DIV");
				tmp.innerHTML = address;
				addressOneLine = (tmp.textContent || tmp.innerText || "")
					.replace(/\n/g, "::")
					.split("::")
					.map(a => a.trim())
					.filter(a => a)
					.join(", ");
			}
			const { phone } = extras[lang] || {};

			return (
					<div className="map-container">
					<ContentSection>

						<div className="content-width">
							<article
								className="contact-info"
							>
								{title ? (
									<React.Fragment>
										<h2 className="title">
											{this.locale(title)}
										</h2>
										<RawHtml html={address} />
										{phone && (
											<div>
												{"T: "}
												<a href={`tel:${phone}`}>
													{phone}
												</a>
											</div>
										)}
										 <div>
                        {"E: "}
                        <a href={`mailto:${emailAddress}`}>
                          {emailAddress}
                        </a>
                      </div>


{addressOneLine&&<React.Fragment><br/><a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addressOneLine)}`} target="_blank"
      rel="noopener noreferrer">
						{locale.OPEN_MAPS}
					</a></React.Fragment>}


									</React.Fragment>
								) : null}
							</article>
						</div>

					</ContentSection>
				{addressOneLine && (
							<OfficeMap address={addressOneLine} />
						)}
					</div>
			);
		}
	}
);
export default ContactPage;
