import {addCacheTimestamp, slugify} from "utils"
const defaultState = false;

export default (state = defaultState, action) => {
	switch (action.type) {
		case "WHATWEDO_RECEIVED":
			return addCacheTimestamp({whatwedo:action.whatwedo.map(({content,title})=>({
				name:title,
				content,
				path:{
					nl:'/'+slugify(title.nl||""),
					en:'/'+slugify(title.en||""),
				}
			}))});
		default:
			return state;
	}
};
