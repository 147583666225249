import React from "react";
import {connect} from "react-redux";
import "./NotFound.css"
import { Link } from "components";


const NotFoundPage = connect(({location, page,locale}) => ({
    lang: location.lang,
    pageData: page[(location.menuRoot || {}).source || -1],locale
}))(
    class extends React.Component {
        locale(prop, lang) {
            if (!prop) {
                return null;
            }
            return typeof prop === "string"
                ? prop
                : prop[lang || this.props.lang];
        }

        render() {
            const {locale} = this.props
            return (
                <div className="container">
                    
                        <h1>404</h1> <h2 className="title">{locale.PAGE_NOT_FOUND}</h2>
                    
                    <Link>{locale.BACK_TO_HOME}</Link>
                </div>
            );
        }
    }
);
export default NotFoundPage;
