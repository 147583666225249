import React from "react";
import { connect } from "react-redux";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import { withRouter } from "react-router-dom";
import { baseHostName } from "env";

const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis);

const RawHtml = connect()(
	class extends React.Component {
		constructor(props) {
			super(props);
			this.container = React.createRef();
		}
		contentClickHandler = e => {
			if (e.ctrlKey) {
				return;
			}

			const targetLink = e.target.closest("a");
			if (!targetLink) {
				return;
			}
			if (targetLink.hash && targetLink.getAttribute("href")[0] === "#") {
				return;
			}
			const href = targetLink.pathname;
			const host = targetLink.host;
			if (
				!href ||
				(host !== window.location.host && host !== baseHostName)
			) {
				return;
			}
			e.preventDefault();
			this.props.history.push(href + targetLink.search);
		};

		render() {
			const {
				html,
				className,
				style,
				truncate,
				Component = "div"
			} = this.props;

			if (truncate) {
				const match = html.trim().match(/^<p>(.{150,500})<\/p>/);

				/*	return (<Truncate 
  lines={truncate} 
  breakWord={true} 
  responsive={true} 
  dangerouslySetInnerHTML={{
    __html: html
  }}
  ref={this.container}
/>)*/
				return (
					<ResponsiveHTMLEllipsis
						style={style}
						className={className}
						component={Component}
						unsafeHTML={match ? match[0] : html}
						maxLine={truncate}
						ellipsis="..."
						basedOn="letters"
						ref={this.container}
					/>
				);
			}
			return (
				<Component
					style={style}
					className={className}
					onClick={this.contentClickHandler}
					dangerouslySetInnerHTML={{
						__html: html
					}}
					ref={this.container}
				/>
			);
		}
	}
);

export default withRouter(RawHtml);
