import {addCacheTimestamp} from "utils"
const defaultState = {};

export default (state = defaultState, action) => {
	switch (action.type) {
		case "CASES_RECEIVED":
	
			return addCacheTimestamp({list:action.cases});
		default:
			return state;
	}
};
