import { takeLeading, put, select } from "redux-saga/effects";
import {cacheIsValid} from "utils"
import api from "utils/api";

function* casesFetch() {

	const cache = yield select(({ cases }) => cacheIsValid(cases,30));
	if (cache) {
		return false;
	}

	const cases = yield api("/clients", {}, { method: "GET" });

	if (!cases) {
		return;
	}
	yield put({
		type: "CASES_RECEIVED",
		cases
	});
}

export default function*() {
	yield takeLeading("CASES_FETCH", casesFetch);
}
