const defaultState = {};

export default (state = defaultState, action) => {
	switch (action.type) {
		case "COORDINATES_RECEIVED":
			return {...state,[action.address]:action.coordinates};
		default:
			return state;
	}
};
