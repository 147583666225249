import React from "react";
import { connect } from "react-redux";
import "./Background.css";
import Media from 'react-media';

const Background = connect(({ ui, location }) => {
	const { menuRoot = {} } = location;
	const { bg } = menuRoot;

	return {
		imgExtension: ui.imgExtension,
		bg
	};
})(
	class extends React.Component {
		state = { backgrounds: [], bgindex: 0 };

		componentDidUpdate({ bg: prevBg }) {
			const { bg } = this.props;
			const { bgindex, backgrounds: prev } = this.state;
			if (prevBg !== bg || bgindex === 0) {
				const backgrounds = [
					...prev,
					{
						key: bgindex,
						bg
					}
				];
				this.setState({
					bgindex: bgindex + 1,
					backgrounds
				});
				clearTimeout(this.restore);
				if (backgrounds.length > 1) {
					this.restore = setTimeout(() => {
						this.setState({
							backgrounds: this.state.backgrounds.slice(-1)
						});
					}, 2000);
				}
			}
		}

		handleImageLoaded(key) {
			const { backgrounds } = this.state;
			this.setState({
				backgrounds: backgrounds.map(bg => {
					if (bg.key === key) {
						bg.ready = 1;
					}
					return bg;
				})
			});
		}

		render() {
			const { imgExtension } = this.props;
			const { backgrounds } = this.state;
			return (
				<div
					className="background-cont"
					style={{
						...this.props.style
					}}
				>


<Media queries={{
          "phone-portrait": "(max-width: 500px) and (max-height: 1001px)",
          "phone-landscape": "(max-height: 500px) and (max-width: 1001px)",
          "ipad-portrait": "(max-width: 1000px) and (min-height:800px)",
          "2k": "(min-width: 1300px)",
          "4k": "(min-width: 3000px)",
        }}>
        {(queries)=>{
        	const resolution=Object.keys(queries).find(key=>queries[key])||"1k"
        	return (imgExtension
						? backgrounds.map(({ bg, key, ready }) =>
								bg ? (
									<div
										className={`img-load${
											ready ? " img-ready" : ""
										}`}
										key={key.toString()}
									>
										<img
											alt={bg}
											src={`/bg/${resolution}/${bg}.${imgExtension}`}
											onLoad={() =>
												this.handleImageLoaded(key)
											}
										/>
									</div>
								) : (
									<React.Fragment key={key} />
								)
						  )
						: null)
        }}
        </Media>

					
				</div>
			);
		}
	}
);
export default Background;
