import React from "react";
import {connect} from "react-redux";
import {OnlyOnDesktop, Link} from "components";

const SidebarLinks = connect(({location, page, ...rest}) => {

    const {storeKey, template} = location.menuRoot || {}

    if (
        !(
            template === "text-sections" ||
            (Array.isArray(template) && ~template.indexOf("text-sections"))
        )
    ) {
        return {};
    }
    const list = storeKey
        ? (rest[storeKey] || {})[storeKey] || []
        : location.menuRoot.children || [];

    return {
        menuRoot: location.menuRoot,
        lang: location.lang,
        list
    };
})(
    class extends React.Component {
        locale(prop, lang) {
            if (!prop) {
                return null;
            }
            return typeof prop === "string"
                ? prop
                : prop[lang || this.props.lang];
        }

        render() {
            const {menuRoot, list} = this.props;
            if (!list || !list.length) {
                return null;
            }
            const {path: rootPath, ref} = menuRoot || {};
            const rootPathLocale = this.locale(rootPath);

            return (
                <OnlyOnDesktop>
                    <React.Fragment>
                        <div className="content-wrap sidebar-links-cont">
                            <div className="content-grid content-width">
                                <div className="sidebar-links">
                                    {list.map(({path, name}, i) => (
                                        <Link
                                            nav={true}
                                            key={ref + i.toString()}
                                            className="sidebar-link"
                                            to={`${rootPathLocale}${this.locale(
                                                path
                                            )}`}
                                        >
                                            {this.locale(name)}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                marginTop: `calc(${-40 *
                                list.length}px - var(--gap))`
                            }}
                        />
                    </React.Fragment>
                </OnlyOnDesktop>
            );
        }
    }
);

export default SidebarLinks;
