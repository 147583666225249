import { takeLeading, put, select } from "redux-saga/effects";
import {cacheIsValid} from "utils"
import api from "utils/api";

function* teamFetch() {

	const cache = yield select(({ team }) => cacheIsValid(team,50));
	if (cache) {
		return false;
	}

	const team = yield api("/members", {}, { method: "GET" });

	if (!team) {
		return;
	}

	yield put({
		type: "TEAM_RECEIVED",
		team
	});
}

export default function*() {
	yield takeLeading("TEAM_FETCH", teamFetch);
}
