const defaultState = {};

export default (state = defaultState, action) => {
	switch (action.type) {
		case "SEARCH_QUERY":
			return {
				...state,
				q: action.q
			};
		case "SEARCH_SUGGESTIONS_RECEIVED":
			return {
				...state,
				suggestions: action.suggestions
			};
		default:
			return state;
	}
};
