import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createBrowserHistory } from "history";

import createSagaMiddleware from "redux-saga";

import { reducer as reduxFormReducer } from "redux-form";

//import createRootReducer from "./reducers/_rootReducer";
import rootSaga from "redux/sagas";

////////////////////
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import reduxModules from "redux/reducers";

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    form: reduxFormReducer, // mounted under "form"
    ...reduxModules
  });

const persistConfig = {
  key: "root",
  whitelist: ["page","coordinates","timeline","cases","whatwedo"],
  storage
};

export default (preloadedState = {},persistReadyCallback) => {
  const sagaMiddleware = createSagaMiddleware();

  const history = createBrowserHistory();

  //const persistedReducer = persistReducer(persistConfig, rootReducer);
  const persistedReducer = persistReducer(
    persistConfig,
    createRootReducer(history)
  );

  const store = createStore(
    persistedReducer,
    preloadedState,
    compose(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  );

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store,null,persistReadyCallback?()=>persistReadyCallback(history):null);

  return { store, persistor, history };
};
