import React from "react";
import { connect } from "react-redux";
import { ContentSection, Link, Image, RawHtml } from "components";
import { articleGroups, menu } from "env";
import { articlePath } from "utils";
import Media from 'react-media';

const FrontPage = connect(({ location, news, locale }) => ({
	locale,
	lang: location.lang,
	articles: ((news[location.lang] || {}).front)
}))(
	class extends React.Component {
componentDidMount() {
			this.componentDidUpdate();
		}
		componentDidUpdate({ lang:prevLang } = {}) {
			const { lang } = this.props;
			if(lang && lang!==prevLang){
			this.props.dispatch({
				type: "NEWS_FRONT_FETCH",
				lang
			});
		}
						}


		locale(prop) {
			if (!prop) {
				return "";
			}
			const { lang } = this.props;
			return typeof prop === "string" ? prop : prop[lang];
		}

		renderArticles(articles,truncate=3) {
			const { locale } = this.props;
			return articles.map(
				({ type, title, image, description, id = "" }, i) => {
					const props = {
						key: `${i}-${id}`,
						className: `${
							image ? "block-sq2 " : ""
						}block block-link`
					};
					if (!id) {
						return <div {...props} />;
					}
					const localeTitle = this.locale(title);
					return (
						<Link {...props} to={articlePath(id, localeTitle)}>
							{image && <div className="thumb-cont"><Image src={image} className="thumb" /></div>}

							<span className="block-content">
								<h2 className="block-title">{localeTitle}</h2>
								<span className="block-label">
									{this.locale(articleGroups[type])}
								</span>

								<RawHtml
									truncate={truncate}
									className="block-lead"
									html={this.locale(description) || ""}
								/>

								<span className="read-more">
									{locale.READ_MORE + "..."}
								</span>
							</span>
						</Link>
					);
				}
			);
		}

		pageLink(ref, className = "") {
			const menuItem = menu.find(m => m.ref === ref);
			if (menuItem) {
				return (
					<Link
						to={this.locale(menuItem.path)}
						className={`block block-link block-ctr-title ${className}`}
					>
						<h2>{this.locale(menuItem.name)}</h2>
					</Link>
				);
			}
			return null;
		}

		render() {
			const { articles } = this.props;

			return (
				<ContentSection>
					<div
						className="content-width content-grid content-grid-square"
						style={{ userSelect: "none" }}
					>
						{!articles ? (
							this.renderArticles(Array(12).fill({}))
						) : (
						<Media queries={{
          mobile: "(max-width: 600px)",
        }}>
        {({mobile})=>{

        	const truncate=mobile?2:3
        	return (<React.Fragment>
								{this.pageLink("who-we-are")}

								{this.renderArticles(articles.slice(0, 2),truncate)}
								{this.pageLink("what-we-do", "red-bg")}

								{this.renderArticles(articles.slice(2),truncate)}
							</React.Fragment>)
        }}</Media>
							
						)}
					</div>
				</ContentSection>
			);
		}
	}
);

export default FrontPage;
