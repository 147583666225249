import React from "react";
import { connect } from "react-redux";
import { twitterAccount } from "env";

import Linkify from "react-linkify-always-blank";
import "./TwitterFeed.css";
import moment from "moment";
import {Throbber} from "components";

const TwitterFeed = connect(({ location, twitter }) => ({
	lang: location.lang,
	twitter: twitter.filter(({ lang }) => lang === location.lang)
}))(
	class extends React.Component {
		componentDidMount() {
			this.props.dispatch({
				type: "TWITTER_FETCH"
			});
		}
		
		render() {
			const { lang, twitter = [], className, style } = this.props;

			moment.locale(lang);

			return (
				<div
					className={className}
					style={{
						display: "flex",
						flexDirection: "column",
						...style
					}}
				>
					<div className="block-content block-header">
						<h2 className="block-title">Tweets</h2>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`https://twitter.com/${twitterAccount}`}
						>{`@${twitterAccount}`}</a>
					</div>

					<div className={`${twitter.length ? "" : "throbbing"}`} style={{ flex: 1, position:"relative" }}>
						{twitter.length ?
					<div style={{ position:"absolute", overflow: "auto",width:"100%",height:"100%" }}>
						{twitter.map(({ id_str, text, created_at, user }) => {
							const parsedDate = moment(
								created_at,
								"ddd MMM D HH:mm:ss ZZ YYYY"
							).locale(lang);
							const tweetUrl = `https://twitter.com/${
								user.screen_name
							}/status/${id_str}`;
							return (
								<div
									className="tweet block-link block-content border-block"
									key={id_str}
								>
									<a
										className="tweet-overlay-link"
										target="_blank"
										rel="noopener noreferrer"
										href={tweetUrl}
									>
										{tweetUrl}
									</a>

									<span className="tweet-body">
										<Linkify>{text}</Linkify>
									</span>
									<time
										className="read-more"
										dateTime={parsedDate.format(
											"YYYY-MM-DD"
										)}
									>
										{parsedDate.format("LL")}
									</time>
								</div>
							);
						})}
					</div> : <Throbber/>}
					</div>
				</div>
			);
		}
	}
);

export default TwitterFeed;
