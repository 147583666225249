import { lazy } from "react";
import AppLayout from "./AppLayout";
import ContentSection from "./ContentSection";

import Background from "./Background";
import Image from "./Image";
import Page from "./Page/";
import Banner from "./Banner";
import OfficeMap from "./OfficeMap";
import HtmlBody from "./HtmlBody";
import {
	OnlyOnDesktop,
	MediaSwitch,
	NotOnPhone,
	NotOnDesktop,
	OnlyOnTablet,
	NotOnTablet,
	OnlyOnPhone
} from "./MediaQuerySwitches";
import Link from "./Link";
import BehindTheNumbers from "./BehindTheNumbers";
import MenuTop from "./MenuTop";
import MenuTree from "./MenuTree";

import MetaTags from "./MetaTags";

import PageMeta from "./PageMeta";
import SidebarLinks from "./SidebarLinks";

import RawHtml from "./RawHtml";

import ScrollToHere from "./ScrollToHere";
import Throbber from "./Throbber";
import TwitterFeed from "./TwitterFeed";

const Footer = lazy(() => import("./Footer"));
const Team = lazy(() => import("./Team"));
const Timeline = lazy(() => import("./Timeline"));

export {
	AppLayout,
	ContentSection,
	Background,
	Image,
	Page,
	Footer,
	OfficeMap,
	HtmlBody,
	MenuTree,
	MenuTop,
	MetaTags,
	Link,
    Banner,
	BehindTheNumbers,
	OnlyOnDesktop,
	MediaSwitch,
	NotOnPhone,
	NotOnDesktop,
	OnlyOnTablet,
	OnlyOnPhone,
	NotOnTablet,
	PageMeta,
	SidebarLinks,
	RawHtml,
	ScrollToHere,
	Team,
	Throbber,
	Timeline,
	TwitterFeed
};
