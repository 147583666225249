import React from "react";
import { connect } from "react-redux";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";

const ScrollToHere = connect(({  ui }) => ({

	scrollPosStamp: ui.scrollPosStamp
}))(class extends React.Component {
	render(){
		const {scrollPosStamp,offset=-200}=this.props
		return (scrollPosStamp?<ScrollIntoViewIfNeeded 
					key={scrollPosStamp}
					options={{
						behavior:"instant",
						block:"start"
					}}
					style={{position:"relative",top:`${offset}px`}}
					><div/></ScrollIntoViewIfNeeded>:null)

	}
});

export default ScrollToHere;
