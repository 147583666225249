import {addCacheTimestamp} from "utils"
const defaultState = false;

export default (state = defaultState, action) => {
	switch (action.type) {
		case "TIMELINE_RECEIVED":
		
			return addCacheTimestamp({timeline:action.timeline});
		default:
			return state;
	}
};
