import { takeLeading, put, select } from "redux-saga/effects";
import {cacheIsValid} from "utils"
import api from "utils/api";

function* whatwedoFetch() {

	const cache = yield select(({ whatwedo }) => cacheIsValid(whatwedo,60));
	if (cache) {
		return false;
	}

	const whatwedo = yield api("/whatwedo", {}, { method: "GET" });

	if (!whatwedo) {
		return;
	}

	yield put({
		type: "WHATWEDO_RECEIVED",
		whatwedo
	});
}

export default function*() {
	yield takeLeading("WHATWEDO_FETCH", whatwedoFetch);
}
