//import moment from "moment";

import moment from "moment";
import 'moment/locale/nl'

import { isProduction } from "env";

const tsKey = "__TIMESTAMP__";
const getTimestamp = () => moment().unix();
export const addCacheTimestamp = obj => ({
	...obj,
	[tsKey]: getTimestamp()
});

export const headTitle = title=>{
	return title?`${title} | SPJ`:'SPJ'
}

export const cacheIsValid = (obj, expireSeconds = 3600) => {
	if (!obj || !obj[tsKey]) {
		return false;
	}
	return obj[tsKey] + expireSeconds > getTimestamp();
};
export function articlePath(id, title) {

			return `/newsroom/${id}/${slugify(title)}`;
		}
export function slugify(text){
  return (text||'').toString().toLowerCase()
    .replace(/\s+/g, '-')           
    .replace(/[^\w-]+/g, '')      
    .replace(/--+/g, '-')        
    .replace(/^-+/, '')            
    .replace(/-+$/, '');       
}

export const space = "\u00A0";


export function arrayChunks(arr, chunkSize) {
	if (!arr.length) {
		return [];
	}
	let ret = [];
	do {
		ret.push(arr.slice(0, chunkSize));
		arr = arr.slice(chunkSize);
	} while (arr.length);
	return ret;
}

export const log = (...args) => {
	if (isProduction) {
		return;
	}
	if (
		typeof args[0] === "string" &&
		~["undefined", "string"].indexOf(typeof args[1])
	) {
		let color, background;
		switch (args[1]) {
			case "warning":
				color = "red";
				background = "yellow";
				break;
			case "important":
				color = "white";
				background = "red";
				break;
			default:
				color = "green";
				background = "#CFF";
		}
		return console.log(
			`%c ${args[0]}`,
			`background:${background};color:${color}`
		);
	}
	console.log(...args);
};


export const linksToBlank=(html)=>{

	if(!html){
		return '';
	}
	return html.replace(/<a /gi,'<a target="_blank" ')
}