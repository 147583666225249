import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import mapStyle from "./mapStyle";
import "./OfficeMap.css";

const OfficeMap = connect(({ coordinates }, { address }) => ({
	coordinates: coordinates[address]
}))(
	class extends React.Component {
		constructor(props) {
			super(props);
			this.mapRef = React.createRef();
		}
		componentDidMount() {
			const initMap = () => {
				const { google } = window;
				const { coordinates, address } = this.props;
				if (google) {
					if (coordinates) {
						this.showMap(coordinates);
					} else {
						const geocoder = new google.maps.Geocoder();
						geocoder.geocode(
							{
								address
							},
							(results, status) => {
								
								if (status === google.maps.GeocoderStatus.OK) {
									const found = results[0].geometry.location;

									const coordinates = [
										found.lat(),
										found.lng()
									];

									this.props.dispatch({
										type: "COORDINATES_RECEIVED",
										coordinates,
										address
									});

									this.showMap(coordinates);
								}
							}
						);
					}
				}
			};
			if (window.google) {
				initMap();
			} else {
				window.initMap = initMap;

				this.props.dispatch({
					type: "ADD_SCRIPT",
					script: "maps"
				});
			}
		}

		showMap = coordinates => {
			if (!this.mapRef.current) {
				return;
			}

			const { google } = window;
			const center = new google.maps.LatLng(
				coordinates[0],
				coordinates[1]
			);

			const map = new google.maps.Map(this.mapRef.current, {
				center,
				zoom: 14,
				scrollwheel: false,
				zoomControl: true,
				disableDefaultUI: true,
				backgroundColor: "none"
			});

			map.mapTypes.set("spj", new google.maps.StyledMapType(mapStyle));
			map.setMapTypeId("spj");

			new google.maps.Marker({
				map,
				position: center,
				icon: {
					path:
						"m -52.948147,-78.081868 c 0,0 -27.322457,27.322457 -27.34471,27.140271 -0.02225,-0.182186 57.478348,57.6217782 57.444549,57.4445489 C -22.882115,6.3257156 -1.04192,2.4818832 -1.0724175,2.3079561 -1.1029221,2.134022 4.0875458,-21.04619 4.0570412,-21.22011 4.0265366,-21.394044 -52.948147,-78.081868 -52.948147,-78.081868 v 1.4e-5 z",
					fillColor: "#ffffff",
					fillOpacity: 1,
					scale: 1,
					strokeColor: "#999999",
					strokeWeight: 1
				}
			});
			new google.maps.Marker({
				map,
				position: center,
				icon: {
					path:
						"m -45.154006,-47.753959 -1.249896,-2.410768 c -0.442401,-0.934293 -0.390401,-1.672145 0.09369,-2.156237 0.588242,-0.588242 1.575314,-0.525006 2.542091,0.441772 1.009254,1.009253 1.728197,2.216044 2.384067,4.300751 l 4.36321,-0.83404 c -0.399296,-2.205728 -1.749595,-4.639766 -3.803697,-6.662643 -3.471866,-3.413459 -7.627066,-3.643792 -10.510054,-0.840128 -2.503052,2.435594 -1.033917,6.319866 -0.358538,7.705774 l 1.444463,2.795236 c 0.29217,0.703493 0.448957,1.500523 -0.201596,2.151075 -0.799688,0.799689 -1.935111,0.757368 -3.406275,-0.713796 -1.491931,-1.491931 -2.639071,-3.902699 -3.023694,-5.259241 l -4.184877,1.581098 c 0.692872,2.249455 1.869986,4.518115 4.076972,6.725102 3.805104,3.805104 8.096168,4.408337 11.510086,1.310481 2.613771,-2.370173 1.356076,-6.05893 0.324032,-8.134422 m 30.465809,19.338295 -12.052741,12.052741 c -2.646878,2.646878 -3.612397,3.361303 -6.658111,2.793192 l -0.126331,3.569617 c 2.792569,1.3234422 6.488369,1.6174784 9.029838,-0.923991 l 13.649459,-13.649459 -3.842114,-3.842114 z m -17.453934,-4.560591 -1.091702,-1.091702 3.360673,-3.360674 1.30189,1.30189 c 1.141829,1.240838 1.071564,2.42638 0.192545,3.305399 -1.448367,1.448367 -2.751656,0.794371 -3.763406,-0.154913 m 6.362816,-6.530527 -4.766727,-4.766727 -14.636531,14.63653 3.758881,3.758881 5.207869,-5.20787 1.175092,1.175092 c 4.789368,4.789368 8.442218,3.024776 10.605626,0.861369 3.339283,-3.339284 2.372979,-6.7401 -1.344203,-10.457282",
					fillColor: "#1F1E21",
					fillOpacity: 1,
					scale: 1,
					strokeWeight: 0
				}
			});
		};
		render() {
			const {address} =this.props
			return (
				<React.Fragment>
					<Helmet />
					<div className="office-map">
						<div ref={this.mapRef} className="office-map-inner" />
					</div>
					
				</React.Fragment>
			);
		}
	}
);

export default connect()(OfficeMap);
