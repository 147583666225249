import React from "react";
import {connect} from "react-redux"




const PageMeta = connect(({page})=>({
	id:page.id,
	created:page.created,
	changed:page.changed
}))(class extends React.Component {
	render() {
		const { id, created, changed } = this.props;
		return (
			<React.Fragment>
				{id ? <meta itemProp="propertyID" content={id} /> : null}
				{created ? (
					<meta itemProp="datePublished" content={created} />
				) : null}
				{changed ? (
					<meta itemProp="dateModified" content={changed} />
				) : null}
			</React.Fragment>
		);
	}
});

export default PageMeta