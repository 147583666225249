import { call, cancelled } from "redux-saga/effects";
import { log } from "utils";
import { apiUrl } from "env";

export default function*(
  target,
  data = {},
  { method = "POST", hasFiles = false, text = false } = {}
) {
  const uri2url = target => {
    const url = `${apiUrl}${target}`;

    return url;
  };

  let cancelRequest;
  const tryRequest = () => {
    let url = uri2url(target);

    let settings = {
      method,
      mode: "cors", // no-cors, cors, *same-origin
      cache: window.navigator.onLine ? "reload" : "force-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit

      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer" // no-referrer, *client
    };
    if ("AbortController" in window) {
      const controller = new AbortController();
      settings.signal = controller.signal;
      cancelRequest = () => {
        log(`ABORTED ${target}`, "important");

        controller.abort();
      };
    }

    settings.headers = {};

    if (method === "GET") {
      url +=
        "?" +
        Object.entries(data)
          .map(([key, val]) => `${key}=${val}`)
          .join("&");
    } else {
      settings.headers["Content-Type"] = "application/json";
      settings.body = JSON.stringify(data);
    }

    try {
      log(
        `${target} ${Object.keys(data)
          .filter(a => a !== "uid")
          .map(a => a + ":" + JSON.stringify(data[a]))
          .join("__")}`
      );
      const request = fetch(url, settings);

      // if (abortable) {

      if (text) {
        return request.then(response => response.text());
      } else {
        return request
          .then(response => response.json())
          .then(res => {
            if (!res) {
              return false;
            }
            if (
              (res.errorCode && res.errorCode === 1001) ||
              res.validSession === false ||
              (res.error &&
                (res.error === "unknown user" ||
                  res.message === "invalid user"))
            ) {
              return false;
            }
            /* if (res.error) {
            throw Error(res.error);
          }*/
            return res;
          })
          .catch(e => {
            log(e);
          });
      }

      //}
    } catch (e) {
      return false;
    }
  };
  let r
  try {
     r = yield call(tryRequest);
   
  } finally {
    if (yield cancelled()) {
      // Cancel the API call if the saga was cancelled

      if (cancelRequest) {
        cancelRequest();
      }
    }
  }
  return r
}
