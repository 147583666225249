import React, {Suspense} from "react";
import {Page, Background, Banner, MenuTop, Footer, ScrollToHere, SidebarLinks, NotOnPhone} from "components";


const HtmlBody = class extends React.Component {

    render() {
        return (
            <React.Fragment>
              
                <div className="contain-site">
                    <MenuTop/>
                    <ScrollToHere/>
                    <Banner/>
                    <SidebarLinks/>
                    <Page/>
                </div>
                <Suspense fallback={null}><Footer/></Suspense>
                <NotOnPhone>
                  <Background/></NotOnPhone>
            </React.Fragment>
        );
    }
}


export default HtmlBody;
