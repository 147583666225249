import React from "react";
import { connect } from "react-redux";
import Media from 'react-media';
import { Helmet } from "react-helmet"
import { Link, OnlyOnPhone, NotOnPhone } from "components";

import { Logo, Amo } from "icons";
import { menuMain, baseUrl } from "env";
import "./MenuTop.css";

const MenuTop = connect(({ location,ui }) => ({
	lang: location.lang,
	mobileMenuOpen:ui.mobileMenuOpen
}))(
	class extends React.Component {

		locale(prop, lang) {
			return typeof prop === "string"
				? prop
				: prop[lang || this.props.lang];
		}
		toggleMobileMenu = () => {
			this.props.dispatch({
				type:"TOGGLE_MOBILE_MENU"
			})
		};
		closeMobileMenu = () => {
			this.props.dispatch({
				type:"CLOSE_MOBILE_MENU"
			})
		};
		renderMenu(className, useShortName,onClick=null) {
			return menuMain.slice(1).map(({ path, name, short }, i) => (
				<Link
					className={className}
					to={this.locale(path)}
					key={i.toString()}
					nav={true}
					onClick={onClick}
				>
					{this.locale((useShortName&&short)||name)}
				</Link>
			));
		}

		content() {
			const { mobileMenuOpen }=this.props;
			return (
				<React.Fragment>
					<header className="menu-top content-wrap">
						<div className="content-width menu-top-inner">
							<Link to="/" className="menu-logo" onClick={this.closeMobileMenu}>
								<Logo
									className="logo"
									style={{ color: "#1F1E21" }}
								/>
							</Link>
							<div className="menu-right">
								<NotOnPhone>
									<a
										href="https://h-advisors.global/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<Amo
											title="AMO Strategic Advisors"
											className="red menu-amo"
										/>
									</a>
								</NotOnPhone>
								<LangSwitch />

								<OnlyOnPhone>
									<React.Fragment>
										<label
											className={`hamburger${
												mobileMenuOpen
													? " hamburger-checked"
													: ""
											}`}
											onClick={this.toggleMobileMenu}
										>
											<span className="hamb hamb-top">
												<span className="hamb-crust hamb-crust-top" />
											</span>
											<span className="hamb hamb-bottom">
												<span className="hamb-crust hamb-crust-bottom" />
											</span>
										</label>

										<menu className={`mobile-menu${mobileMenuOpen?' shown':''}`}>
											<div className="mobile-menu-inner">
											{this.renderMenu("mob-item",false,this.closeMobileMenu)}
											<a
												href="https://h-advisors.global/"
												target="_blank"
												rel="noopener noreferrer"
											>
												<Amo
													title="AMO Strategic Advisors"
													className="menu-amo"
												/>
											</a>
											</div>
										</menu>
									</React.Fragment>
								</OnlyOnPhone>
							</div>
							<NotOnPhone>
								<menu className="menu-top-nav">
								<Media queries={{
          short: "(max-width: 960px)",
        }}>
									{({short})=>this.renderMenu("menu-item",short)}
									</Media>
								</menu>
							</NotOnPhone>
						</div>
					</header>
				</React.Fragment>
			);
		}
		render() {
			return this.content();
			/*
			return (

				<React.Fragment>
				<OnlyOnPhone>
				<React.Fragment><Headroom>
				{this.content()}
				</Headroom></React.Fragment>
				</OnlyOnPhone>
				<NotOnPhone>
				{this.content()}
				</NotOnPhone>
				</React.Fragment>


				
			);*/
		}
	}
);

export default MenuTop;

const LangSwitch = connect(({ location, ui }) => ({
	lang: location.lang,
	menuRoot: location.menuRoot,
	menuItem: location.menuItem,
	langSwitch: ui.langSwitch
}))(
	class extends React.Component {
		locale(prop, lang) {
			return typeof prop === "string"
				? prop
				: prop[lang || this.props.lang];
		}
		render() {
			const { lang, menuRoot, menuItem, langSwitch } = this.props;
			return (
				<div style={{ display: "flex" }}>

				
					{[
						{ key: "en", label: "English" },
						{ key: "nl", label: "Nederlands" }
					].map(({ key, label }) => {
						const isActive = key === lang;

						const props = {
							key,
							title: label,
							className: `reg-font menu-lang-switch${
								isActive ? " active" : ""
							}`,
							children: key.toUpperCase()
						};

						if(isActive){
							return <span {...props} />
						}
						const to=(langSwitch || {})[key] ||
									(menuRoot
										? this.locale(menuRoot.path, key)
										: "") +
										(menuItem
											? this.locale(menuItem.path, key)
											: "")

						return <React.Fragment key={key}>
							{to&&<Helmet><link rel="alternate" href={baseUrl+'/'+key+to} hreflang={key} /></Helmet>}
							<Link
								{...props}
								lang={key}
								to={
									to
								}
							/></React.Fragment>


					})}
				</div>
			);
		}
	}
);
