import React from "react";
import Media from "react-media";



const queries = {
	phone: "(max-width: 600px)",
	phoneOrTablet:"(max-width: 850px)",
	tablet: "(min-width: 601px) and (max-width: 850px)",
	notOnTablet: "(max-width: 600px), (min-width: 851px)",
	tabletOrDesktop: "(min-width: 601px)",
	desktop: "(min-width: 851px)"
};

export const OnlyOnDesktop = class extends React.Component {
	render = () => <Media query={queries.desktop} {...this.props} />;
};

export const OnlyOnTablet = class extends React.Component {
	render = () => <Media query={queries.tablet} {...this.props} />;
};

export const NotOnTablet = class extends React.Component {
	render = () => <Media query={queries.notOnTablet} {...this.props} />;
};
export const NotOnDesktop = class extends React.Component {
	render = () => <Media query={queries.phoneOrTablet} {...this.props} />;
};
export const NotOnPhone = class extends React.Component {
	render = () => <Media query={queries.tabletOrDesktop} {...this.props} />;
};

export const OnlyOnPhone = class extends React.Component {
	render = () => <Media query={queries.phone} {...this.props} />;
};


export const MediaSwitch = class extends React.Component {
	render = () => {
		const setQueryKeys = Object.keys(queries).filter(
			key => this.props[key]
		);
		const setQueries = {};
		for (const key of setQueryKeys) {
			setQueries[key] = queries[key];
		}

		return (
			<Media queries={setQueries}>
				{m => {
					const mode = Object.keys(m).find(key => m[key]);
					if (!mode) {
						return null;
					}
					const func = this.props[mode];
					switch (typeof func) {
						case "function":
							return func();
						case "string":
							return func;
						default:
							return null;
					}
				}}
			</Media>
		);
	};
};
