import { takeLeading, put, select } from "redux-saga/effects";
import {cacheIsValid} from "utils"
import api from "utils/api";

function* timelineFetch() {

	const cache = yield select(({ timeline }) => cacheIsValid(timeline,30));
	if (cache) {
		return false;
	}

	const timeline = yield api("/trackrecords", {}, { method: "GET" });

	if (!timeline) {
		return;
	}

	yield put({
		type: "TIMELINE_RECEIVED",
		timeline
	});
}

export default function*() {
	yield takeLeading("TIMELINE_FETCH", timelineFetch);
}
