import { put, takeLatest, takeEvery, select } from "redux-saga/effects";

import api from "utils/api";
import { menu } from "env";

function* pageSet({ payload }) {
  const { pathname, search } = payload.location;
  const { isFirstRendering } = payload;

  if (search.match(/^\?p=/)) {
    return;
  }

  const match = pathname.match(/^\/(en|nl)(\/(.*))?$/);

  let lang = "en";

  let path = pathname;
  if (match) {
    lang = match[1];
    path = match[2];
  }
  if (!path) {
    path = "/";
  }
  const locale = prop => (typeof prop === "string" ? prop : prop[lang]);
  let menuRoot = (pathname =>
    pathname
      ? menu.find(({ path }) => pathname.match(new RegExp(`^${locale(path)}/`)))
      : false)(path && path + "/");
  let menuItem;
  if (menuRoot && menuRoot.children) {
    const menuRootPath = locale(menuRoot.path);
    const index = menuRoot.children.findIndex(
      ({ path: subPath }) => menuRootPath + locale(subPath) === path
    );
    if (index >= 0) {
      menuItem = {
        ...menuRoot.children[index],
        index
      };
    }
  }

  yield put({
    type: "LOCATION_SET",
    payload: { lang, path, menuRoot, menuItem }
  });

  if (menuRoot && menuRoot.source) {
    yield put({
      type: "FETCH_PAGE_SOURCE",
      source: menuRoot.source,
      isFirstRendering
    });
  }
}

function* newsFetch({ lang, page, articleId, per_page, promoted, newsType }) {
  if (articleId) {
    const cached = yield select(({ news }) => !!news[articleId]);
    if (!cached) {
      const article = yield api(
        "/news/" + articleId,
        { lang },
        { method: "GET" }
      );

      yield put({
        type: "NEWS_ARTICLE_RECEIVED",
        articleId,
        article,
        lang
      });
    }
  } else if (promoted) {
    const cached = yield select(
      ({ news }) => !!(news[lang] && news[lang].promoted)
    );
    if (!cached) {
      const promotedArticles = yield api(
        "/news",
        { lang, page: 1, per_page: promoted },
        { method: "GET" }
      );
      yield put({
        type: "NEWS_PROMOTED_RECEIVED",
        promotedArticles,
        lang
      });
      yield put({
        type: "NEWS_FETCH",
        lang,
        page,
        per_page,
        newsType
      });
    }
  } else {
    const filterOnType = typeof newsType === "number";

    const langKey = filterOnType ? newsType + "-" + lang : lang;
    const cached = yield select(
      ({ news }) => !!(news[langKey] && news[langKey][page])
    );

    if (!cached) {
      const exclude = yield select(({ news }) =>
        news[lang] ? news[lang].promoted.data.map(({ id }) => id).join(",") : ""
      );

      const news = yield api(
        "/news",
        {
          lang,
          page,
          per_page,
          exclude,
          ...(filterOnType ? { type: newsType } : {})
        },
        { method: "GET" }
      );
      yield put({
        type: "NEWS_RECEIVED",
        news,
        lang: langKey,
        page,
        ...(filterOnType ? { newsType } : {})
      });
    }
  }
}

function* newsFrontFetch({ lang }) {
  const cached = yield select(({ news }) => !!(news[lang] && news[lang].front));
  if (!cached) {
    const front = yield api(
      "/news",
      { lang, page: 1, per_page: 10 },
      { method: "GET" }
    );

    let articles = [];
    let maxImgs=3;
    let count = 0;
    const until = 10;
    if (front && front.data) {
      for (let article of front.data) {
        if(article.image){
          if(maxImgs--<=0){
              delete article.image
          }
        }

        count += article.image ? 2 : 1;
        if (count >= until) {
          articles.push(
            count === until ? article : { ...article, image: null }
          );
          break;
        }
        articles.push(article);
      }
    }
    yield put({
      type: "NEWS_FRONT_RECEIVED",
      front: articles,
      lang
    });
  }
}

function* pageSourceFetch({ source, isFirstRendering }) {
  const cached = isFirstRendering
    ? false
    : yield select(({ page }) => !!page[source]);
 // if (!cached) {
    const page = yield api(source, {}, { method: "GET" });
    yield put({
      type: "PAGE_RECEIVED",
      page,
      source
    });
 // }
}
function* twitterFetch() {
  const feed = yield api("/twitter", {}, { method: "GET" });
  yield put({
    type: "TWITTER_RECEIVED",
    feed
  });
}

function* newsQueryFetch({ lang, q }) {
  
  yield new Promise(y => setTimeout(y, 500));

  const exclude = yield select(({ news }) =>
    news[lang] ? news[lang].promoted.data.map(({ id }) => id).join(",") : ""
  );
  const filteredNews = yield api(
    "/news",
    { lang, page: 0, per_page: 100, exclude, search: q },
    { method: "GET" }
  );
  yield put({
    type: "FILTERED_NEWS_RECEIVED",
    filteredNews
  });
}

export default function*() {
  yield takeLatest("@@router/LOCATION_CHANGE", pageSet);
  yield takeEvery("FETCH_PAGE_SOURCE", pageSourceFetch);
  yield takeLatest("NEWS_QUERY_FETCH", newsQueryFetch);
  yield takeEvery("NEWS_FETCH", newsFetch);
  yield takeEvery("NEWS_FRONT_FETCH", newsFrontFetch);
  yield takeLatest("TWITTER_FETCH", twitterFetch);
}
