import locale from "locale";


const defaultState = {
};

export default (state = defaultState, action) => {
	switch (action.type) {

		case "LOCATION_SET":

			return locale[action.payload.lang];

		default:
			return state;
	}
};
