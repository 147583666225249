import { all } from "redux-saga/effects";

import timeline from "./timelineSagas";
import cases from "./casesSagas";
import location from "./locationSagas";
import whatwedo from "./whatwedoSagas";
import team from "./teamSagas";
export default function* rootSaga() {
	yield all([timeline(),cases(),location(),whatwedo(),team()]);
}
