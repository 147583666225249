import React from "react";
const ContentSection = class extends React.Component {
	render() {
		const { children } = this.props;
		return (
			<main
				className={`main content-wrap ${this.props.className || ""}`}
				style={{
					...this.props.style
				}}
			>
				{children}
			</main>
		);
	}
};
export default ContentSection;
