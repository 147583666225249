import React from "react";
import { uploadsCdnUrl } from "env";

const Image = class extends React.Component {
  render() {
    const { src, ...tail } = this.props;

    return (
      <img
        alt="img"
        {...tail}
        src={src.replace(/^storage\//, uploadsCdnUrl)}
      />
    );
  }
};

export default Image;
