const defaultState = {};

export default (state = defaultState, action) => {
	switch (action.type) {
		case "NEWS_RECEIVED":
			const { data, per_page, total, last_page } = action.news;

			const articles = {};
			const pageIds = [];
			for (const article of data) {
				pageIds.push(article.id);
				articles[article.id] = article;
			}
			return {
				...state,
				...articles,
				[action.lang]: {
					...state[action.lang],
					[action.page]: pageIds,
					per_page,
					total,
					last_page
				}
			};
		case "NEWS_ARTICLE_RECEIVED":
			return {
				...state,
				[action.articleId]: action.article
			};
		case "NEWS_PROMOTED_RECEIVED":
			return {
				...state,
				[action.lang]: {
					...state[action.lang],
					promoted: action.promotedArticles
				}
			};
			case "NEWS_FRONT_RECEIVED":
			return {
				...state,
				[action.lang]: {
					...state[action.lang],
					front: action.front
				}
			};
		default:
			return state;
	}
};
