import React from "react";
import { connect } from "react-redux";
import {
	ContentSection,

	RawHtml

} from "components";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import {linksToBlank} from "utils"

const TextSectionsPage = connect(({ location, page, ...rest }) => {
	const { storeKey } = location.menuRoot;

	const pageData = page[(location.menuRoot || {}).source || -1];
	return {
		lang: location.lang,
		menuItem: location.menuItem,
		pageData,
		activeSection:
			"/" +
			location.path
				.split("/")
				.slice(2)
				.join("/"),
		list: storeKey
			? (rest[storeKey] || {})[storeKey] || []
			: location.menuRoot.children || []
	};
})(
	class extends React.Component {
		locale(prop, lang) {
			if (!prop) {
				return null;
			}
			return typeof prop === "string"
				? prop
				: prop[lang || this.props.lang];
		}
		render() {
			const { list, pageData, activeSection } = this.props;
		
	

			const renderList = list
				.filter(({ type }) => !type)
				.map(o => ({
					...o,
					...(pageData
						? {
								name: pageData.title,
								content: pageData.content
						  }
						: {})
				}));

			return (
				<React.Fragment>
					<ContentSection>
						<div className="content-width content-grid handheld-center-content">
						<div className="content-pad-cell" />
							{/*<OnlyOnDesktop>
								<div className="sidebar-links">
									{list.map(({ path, name }, i) => (
										<Link
											nav={true}
											key={i.toString()}
											className="sidebar-link"
											to={`${rootPathLocale}${this.locale(
												path
											)}`}
										>
											{this.locale(name)}
										</Link>
									))}
								</div>
							</OnlyOnDesktop>*/}

							<article className="block span-2 article-sections article">
								{renderList.length ? (
									renderList.map(
										({ name, content, path }, i) => {
											const isActive =
												activeSection ===
												this.locale(path);

											return (
												<section
													key={i.toString()}
													className={
														`block-content${isActive
															? " section-active"
															: ''}`
													}
												>
													{isActive && (
														<ScrollIntoViewIfNeeded
															options={{
																scrollMode:
																	"always",
																block: "start",
																behavior:
																	"smooth"
															}}
															style={{
																position:
																	"absolute",
																width: 10,
																height:
																	"calc(100% + var(--menuHeight))",
																bottom: 0
															}}
															children={<div />}
														/>
													)}

													<h2 className="title">
														{this.locale(name)}
													</h2>
													<RawHtml
														html={linksToBlank(this.locale(
															content
														))}
													/>
												</section>
											);
										}
									)
								) : (
									<div
										className="article-loading"
										style={{
											"--estimateHeight": list.length || 5
										}}
									/>
								)}
							</article>
						</div>
					</ContentSection>
				</React.Fragment>
			);
		}
	}
);
export default TextSectionsPage;
