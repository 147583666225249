import React from "react";
import { connect } from "react-redux";
import { ContentSection, RawHtml } from "components";
import { space, linksToBlank } from "utils";
import { Amo } from "icons";

const DefaultPage = connect(({ page, location }) => ({
	lang: location.lang,
	pageData: page[(location.menuRoot || {}).source || -1]
}))(
	class extends React.Component {
		locale(prop, lang) {
			if (!prop) {
				return null;
			}
			return typeof prop === "string"
				? prop
				: prop[lang || this.props.lang];
		}
		render() {
			const { pageData = {} } = this.props;
			const { title, content, extras } = pageData;
			let links = [];
			if (extras) {
				const l_extras = this.locale(extras);
				if (l_extras.links) {
					links = JSON.parse(l_extras.links);
				}
			}
			return (
				<ContentSection>
					<div className="content-width content-grid handheld-center-content">
						<div className="content-pad-cell" />
						<article className="block block-content block-article article">
							{title ? (
								<React.Fragment>
									<h2 className="title">
										{this.locale(title)}
									</h2>
									<RawHtml html={linksToBlank(this.locale(content))} />
									{links && links.length ? (
										<div>
											{links.map(
												(
													{ link, text, location },
													i
												) => (
													<div key={i.toString()}>
														{link && (
															<a
																href={link}
																target="_blank"
																rel="noopener noreferrer"
															>
																{text || link}
															</a>
														)}
														{location && (
															<span>
																{space}
																{location}
															</span>
														)}
													</div>
												)
											)}

											<a
												href="https://h-advisors.global/"
												target="_blank"
												rel="noopener noreferrer"
											>
												<Amo
													style={{
														height: "70px",
														marginTop: 20,
														display: "inline-block",
														float: "right"
													}}
												/>
											</a>
										</div>
									):null}
								</React.Fragment>
							) : (
								<div
									className="article-loading"
									style={{ "--estimateHeight": 5 }}
								/>
							)}
						</article>
					</div>
				</ContentSection>
			);
		}
	}
);
export default DefaultPage;
