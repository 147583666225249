//import './wdyr';

import React from "react";


import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "connected-react-router";
import supportsWebP from "supports-webp";

import * as serviceWorker from "./serviceWorker";

import { AppLayout, MetaTags } from "components";
//import { thirdPartyInit } from "thirdParty";

import configureStore from "./configureStore";

const persistReadyCallback = history => {
  store.dispatch({
    type: "WHATWEDO_FETCH"
  });

  /*
  const { preloadStore } = window;
  let setFromPreload
  if (preloadStore) {
      const {app, page}=preloadStore
      if(app && page){
    store.dispatch({
        type: "APP_GLOBALS_RECEIVED",
        app
      })
     store.dispatch({
        type: "PAGE_TO_STORE",
        page,
        firstPageFetch:true
      });

    }
  } 

   if(!setFromPreload){
    store.dispatch({
      type: "LOCATION_INIT",
      pathname: window.location.pathname,
      search: window.location.search,
      full: true
    });
  }

  history.listen((location, direction) => {

    store.dispatch({
      type: "LOCATION_CHANGED",
      pathname: location.pathname,
      search: location.search,
      direction,
      full: false
    });
  });*/

  if (typeof URL != "undefined") {
    /*
    Github pages spa hack
    404 document redirects to main index, with ?p=/PATH param
  */

    const url = new URL(document.location);
    const p = url.searchParams.get("p");
    if (p) {
      history.push(p);
    }else if (url.pathname === "/") {
      const browserLanguages = window.navigator
        ? window.navigator.languages || [
            window.navigator.language || window.navigator.userLanguage
          ]
        : [];
        if(browserLanguages.length && browserLanguages[0].split('-')[0]==='nl'){
      
            history.push('/nl');
          
        }
    }

    
  }

  supportsWebP.then(supported => {
    store.dispatch({
      type: "WEBP_SUPPORT",
      supported
    });
  });
};

const { store, persistor, history } = configureStore({}, persistReadyCallback);

function WrappedApp() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <MetaTags />
          <AppLayout />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

ReactDOM.render(<WrappedApp />, document.getElementById("root"));

//thirdPartyInit();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
