import React, { Suspense } from "react";
import { connect } from "react-redux";
import { Timeline, Team } from "components";
import CasesPage from "./CasesPage";
import ContactPage from "./ContactPage";
import FrontPage from "./FrontPage";
import DefaultPage from "./DefaultPage";
import NotFoundPage from "./NotFoundPage";
import NewsPage from "./NewsPage";
import TextSectionsPage from "./TextSectionsPage";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";

const Page = connect(({ location, page }) => {
    const { menuRoot } = location;
    let pageData;
    if (menuRoot && menuRoot.source && page[menuRoot.source]) {
        pageData = page[menuRoot.source];
    }
    return {
        menuRoot,
        pageData,
        path: location.path,
        lang: location.lang
    };
})(
    class extends React.Component {
        pathMaps = {
            "/rechter-als-rustpunt": "/newsroom/571/rechter-als-rustpunt",
            "/naar-de-rechter": "/newsroom/563/naar-de-rechter",
            "/een-jaar-van-uitersten":
                "/newsroom/559/een-jaar-van-uitersten",
            "/kapitalistische-honden":
                "/newsroom/548/kapitalistische-honden",
            "/triumph- ": "/newsroom/549/triumph",
            "/elkaar-het-verlies-gunnen":
                "/newsroom/553/elkaar-het-verlies-gunnen",
            "/duurzaam-deltaplan": "/newsroom/554/duurzaam-deltaplan",
            "/dutch-discount": "/newsroom/547/nieuwe-dutch-discount",
            "/va-bank": "/newsroom/556/va-bank",
            "/nobelprijzen": "/newsroom/555/nobelprijzen",
            "/fons-van-lith-naar-spj":
                "/newsroom/544/fons-van-lith-naar-spj",
            "/bigbank-beweegt-tegen-de-trend":
                "/newsroom/552/bigbank-beweegt-tegen-de-trend-in",
            "/sorry-alleen-niet-genoeg":
                "/newsroom/542/sorry-alleen-is-niet-genoeg",
            "/spj-30-jaar": "/newsroom/541/spj-30-jaar",
            "/new-partner-amo": "/newsroom/540/new-partner-for-amo",
            "/power-and-powerlessness-language":
                "/newsroom/567/the-power-and-powerlessness-of-language",
                "/verzekeren-kan-altijd-nog":"/newsroom/529/verzekeren-kan-altijd-nog",
                "/home":"/",
                "/boardroom-bloopers":"/newsroom/588/achmea-im-joins-forces-in-private-equity",
                "/blog":"/newsroom",
                "/paniekvoetbal":"/newsroom/565/paniekvoetbal",
                "/visma-expands-netherlands":"/newsroom/586/visma-expands-in-the-netherlands"
        };

        render() {
            const { menuRoot = {} } = this.props;
            const { template, children } = menuRoot;
            return this.page(template, children);
        }

        page(template, children) {
            if (Array.isArray(template)) {
                return template.map((tpl, i) => (
                    <React.Fragment key={i.toString()}>
                        {this.page(tpl, children ? children[i] : {})}
                    </React.Fragment>
                ));
            }
            const { pageData = {}, path, lang } = this.props;
            let Component;
            let suspend;

            switch (template) {
                case "front":
                    Component = FrontPage;
                    break;
                case "text-sections":
                    Component = TextSectionsPage;
                    break;
                case "timeline":
                    Component = Timeline;
                    suspend = true;
                    break;
                case "team":
                    Component = Team;
                    suspend = true;
                    break;
                case "default":
                    Component = DefaultPage;
                    break;
                case "cases":
                    Component = CasesPage;
                    break;
                case "contact":
                    Component = ContactPage;
                    break;
                case "news":
                    Component = NewsPage;
                    break;
                case "team-short":
                    Component = Team;
                    pageData.mode = "short";
                    suspend = true;
                    break;
                default:
                    const check = path.replace(/\/$/, "");

                    if (this.pathMaps[check]) {
                        Component = DefaultPage;
                        window.location.href = '/'+lang+this.pathMaps[check];
                    } else {
                        Component = NotFoundPage;
                    }
                    break;
            }

            if (suspend) {
                const isActive =
                    children &&
                    children.path &&
                    "/" +
                        path
                            .split("/")
                            .slice(2)
                            .join("/") ===
                        (typeof children.path === "string"
                            ? children.path
                            : children.path[lang]);

                return (
                    <React.Fragment>
                        {isActive && (
                            <ScrollIntoViewIfNeeded
                                options={{
                                    scrollMode: "always",
                                    block: "start",
                                    behavior: "smooth"
                                }}
                                style={{
                                    position: "relative",
                                    top: "calc(-1 * var(--menuHeight))"
                                }}
                                children={<div />}
                            />
                        )}
                        <Suspense fallback={null}>
                            <Component
                                pageData={pageData}
                                isActive={isActive}
                            />
                        </Suspense>
                    </React.Fragment>
                );
            }
            return <Component pageData={pageData} />;
        }
    }
);

export default Page;
