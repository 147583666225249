import menuconfig from "menu.js"

export const menu=menuconfig

export const isProduction = process.env.NODE_ENV !== "development";

/*
export const { tld, lang, ua } = domains[domainId];
*/

const replaceHostname = s => s.replace("[HOSTNAME]", window.location.hostname);
export const apiUrl = replaceHostname(process.env.REACT_APP_API_URL);

export const baseUrl = process.env.REACT_APP_BASE_URL;
export const baseHostName = baseUrl.split("//").pop();
export const uploadsCdnUrl = 'https://storage.googleapis.com/spj-upload/'
export const attachmentsCdnUrl = 'https://storage.googleapis.com/spj-attachments/'

//export const sendMails = ~~process.env.REACT_APP_SEND_MAILS;
/*
export const smartsuppKey=process.env.REACT_APP_SMARTSUPP_KEY
export const gtmKey=process.env.REACT_APP_GTM_KEY
export const gtmKeyAnalytics=process.env.REACT_APP_GTM_KEY_ANALYTICS


*/

export const emailAddress = "spj@spj.nl";
export const isCrawler = (() => {
    var botPattern =
        "(googlebot/|Googlebot-Mobile|Googlebot-Image|Google Page|ighth|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|httrack|webcrawler|baiduspider|domaincrawler|facebookexternalhit|DuckDuckBot|backlinkcrawler|Twitterbot|Facebot|Twitterbot|Applebot|SemrushBot)";
    var re = new RegExp(botPattern, "i");
    var userAgent = navigator.userAgent;
    if (re.test(userAgent)) {
        return true;
    } else {
        return false;
    }
})();

export const perPageDefault = 5;
export const promotedAmount = 3;

export const twitterAccount = "SPJFinCom";

//export const mapsApiKey = "AIzaSyD04975nZbrzEsxMfBDFhPvh08uA7Kgl9g";
export const mapsApiKey = "AIzaSyD0dMec510WLHyIWnOZh6zAB6VDey_4mQY";

export const articleGroups = {
    0: {
        en: "SPJ News",
        nl: "SPJ Nieuws"
    },
    1: {
        en: "AMO News",
        nl: "AMO Nieuws"
    },
    2: "Blog"
};


export const menuMain = menu.filter(m => !m.menu)
export const menuFooter = menu.filter(m => m.menu === 'footer')

