import React from "react";

import { HtmlBody } from "components";
import { isMobile } from "react-device-detect";
import "css/aboveTheFold/reset.css";
import "css/aboveTheFold/base.css";
import "css/aboveTheFold/block.css";
import "css/aboveTheFold/layout.css";

//const DeferredCss = lazy(() => import("css/DeferredCss"));

class AppLayout extends React.Component {
  /*componentDidMount() {
    document.documentElement.classList.add(isMobile ? "NH" : "H");

    if(!isCrawler){
    var raf =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame;
    if (raf) {
      raf(() => {
        window.setTimeout(() => this.loadDeferredStyles(), 0);
      });
    } else {
      window.addEventListener("load", () => this.loadDeferredStyles());
    }
  }
  }*/

/*
  state = {};
  loadDeferredStyles = () => {
    this.setState({
      deferReady: true
    });
  };
*/
componentDidMount() {
    document.documentElement.classList.add(isMobile ? "NH" : "H");
    }
  render() {
    return (
      <React.Fragment>
        <HtmlBody />
        {/*{isMobile ? null : <PageScrollBinder />}
        {this.state.deferReady ? (
          <Suspense fallback={null}>
            <DeferredCss />
          </Suspense>
        ) : null}*/}
      </React.Fragment>
    );
  }
}

export default AppLayout;

